import './modal.scss';
import axios from 'axios';
import { createElementFromHTML } from '../../utilities/js/helper';

class Modal {
    constructor (element, options) {
        const defaults = {
            initAttr: 'data-modal',
            onAjaxLoaded: null,
            onClosed: null,
            loader: null
        };

        this.settings = Object.assign({}, defaults, options);
        this.modalType = 'content';

        this.$modal = element;

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        this.$modal.addEventListener('click', (e) => {
            e.preventDefault();
            this.openModal();
        });

        if (this.$modal.getAttribute('data-modal-open') !== null) {
            window.setTimeout(() => {
                this.openModal();
            }, 500);
        }
    }

    openModal () {
        const modalId = this.$modal.getAttribute('data-modal');
        this.$modalTarget = document.getElementById(modalId);

        if (this.$modalTarget != null) {
            this.$modalParent = this.$modalTarget.parentNode;
            document.body.appendChild(this.$modalTarget);
        }

        const $currentModal = document.querySelector('.modal.is--active');

        if ($currentModal) {
            const modalAPI = $currentModal.API;
            modalAPI.closeModal();
        }

        if (this.$modalTarget === null) {
            const uri = this.$modal.getAttribute('href');
            this.modalType = 'ajax';

            if (uri !== null) {
                this.loadModal(uri, () => {
                    this.showModal();
                });
            }
        } else {
            this.showModal();
        }
    }

    loadModal (uri, callback) {
        let $loader = null;

        if (this.settings.loader !== null) {
            $loader = createElementFromHTML(this.settings.loader);
        }

        if ($loader !== null) {
            this.$modal.appendChild($loader);
        }

        axios({
            url: uri
        })
            .then((response) => {
                if (typeof response.data.action !== 'undefined' && typeof response.data.content !== 'undefined' && response.data.action === 'render') {
                    const content = response.data.content;
                    const $content = createElementFromHTML(content);
                    document.querySelector('body').appendChild($content);
                    const modalId = $content.getAttribute('id');
                    this.$modalTarget = document.getElementById(modalId);

                    if ($loader !== null) {
                        $loader.parentNode.removeChild($loader);
                    }

                    if (typeof this.settings.onAjaxLoaded === 'function') {
                        this.settings.onAjaxLoaded(this.$modalTarget);
                    }

                    if (typeof callback === 'function') {
                        callback();
                    }
                }
            })
            .catch((error) => {
                throw Error(error);
            });
    }

    showModal () {
        if (this.modalType === 'ajax') {
            window.setTimeout(() => {
                this.$modalTarget.classList.add('is--active');
            }, 250);
        } else {
            this.$modalTarget.classList.add('is--active');
        }

        const $$exists = this.$modalTarget.querySelectorAll('.modal__exit');
        $$exists.forEach(($exit) => {
            $exit.addEventListener('click', (e) => {
                e.preventDefault();
                this.closeModal();
            });
        });

        this.$modalTarget.API = this;
    }

    closeModal () {
        this.$modalTarget.classList.remove('is--active');

        if (this.modalType === 'ajax') {
            window.setTimeout(() => {
                document.body.removeChild(this.$modalTarget);
            }, 250);
        } else {
            this.$modalParent.appendChild(this.$modalTarget);
        }

        if (typeof this.settings.onClosed === 'function') {
            this.settings.onClosed(this.$modalTarget);
        }
    }
}

export { Modal };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $modal = $context.querySelectorAll('[data-modal]');
        for (let i = 0; i < $modal.length; i++) {
            const modal = $modal[i];
            modal.API = new Modal(modal, {
                onAjaxLoaded: ($content) => {
                    const contentUpdateEvent = new CustomEvent('content.loaded', {
                        view: window,
                        bubbles: true,
                        cancelable: false,
                        detail: {
                            $context: $content
                        }
                    });
                    window.dispatchEvent(contentUpdateEvent);
                }
            });
        }
    }
});
